<template>
  <div>
    <video  ref="player" style="width: 100%; height: 100%;"></video>
  </div>
</template>

<script>
import { Player, PLAYER_EVENTS } from '@flussonic/flussonic-webrtc-player';


export default {
  name: 'FlussonicPlayer',
  mounted() {
    this.initFlussonicPlayer();
  },
  methods: {
    initFlussonicPlayer() {
        const url = "https://worldstream72124.com.ua/8080/whap";
        const player = new Player(
  // A <video> or <audio> element to playback the stream from Flussonic
    this.$refs.player,
  // The URL of the stream from Flussonic
  url,
  // Options
  { autoplay: true },
  // Log to console the Player's internal debug messages?
  true,
);
player.on(PLAYER_EVENTS.PLAY, (msg) => console.log(`Play: ${msg}`));
player.on(PLAYER_EVENTS.DEBUG, (msg) => console.log(`Debug: ${msg}`));
player.play();
player.changeTracks('auto');
// ...
// player.stop();
    }
  }
}
</script>

<style scoped>
div {
  width: 100%;
  height: 100%;
}
</style>
