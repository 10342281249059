<template>
  <div id="app">
    <FlussonicPlayer />
  </div>
</template>

<script>
import FlussonicPlayer from './components/FlussonicPlayer.vue';

export default {
  name: 'App',
  components: {
    FlussonicPlayer
  }
}
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
